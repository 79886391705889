import { Grid } from "@material-ui/core"
import { Typography } from "@material-ui/core/"
import * as React from "react"
import { FC, useState } from "react"
import { GiMexico } from "react-icons/gi"
import { IMapCategoryParent } from "../../../pages/mapas"
import { PageHeader, Tabs } from "../../_layout"
import { MapController } from "../index"

import useStyles from "./styles"

interface ICategoryParentTabs {
  categoriasPadre: IMapCategoryParent[]
}

const CategoryParentTabs: FC<ICategoryParentTabs> = ({
                                                       categoriasPadre
                                                     }) => {
  const styles = useStyles()

  const [value, setValue] = useState(0)

  const tabs = categoriasPadre.map(e => {

    return ({
      title: e.categoryParent,
      component: e.categorias ? (
        <MapController
          sectionID="maps-controller"
          maps={ e.categorias || [] }
        />
      ) : (
        <Grid item xs className={ styles.root }>
          <Typography variant="h6">
            No existen mapas en esta categoría.
          </Typography>
        </Grid>
      ),
      icon: null,
      renders: true,
      tooltip: e.categoryParentDescription
    })
  })

  return (
    <Grid item xs className={ styles.root }>
      <PageHeader
        title={ "Consulta mapas de inclusión financiera" }
        icon={ <GiMexico /> }
      />
      <Tabs { ...{ value, setValue, tabs } } dark tabsText={"1. Selecciona la categoría de mapas. "} />
    </Grid>
  )
}

export default CategoryParentTabs
