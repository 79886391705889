import { Grid, Tooltip } from "@material-ui/core"
import Img from "gatsby-image"
import * as React from "react"

import useStyles from "./styles"

import { ISponsorImage } from "./ThanksSection"

export interface ISponsors {
  sponsors: ISponsorImage[]
  horizontal?: boolean
}

const Sponsors: React.FC<ISponsors> = ({ sponsors, horizontal }) => {
  const styles = useStyles({ horizontal })
  return (
    <Grid container className={ styles.sponsorsRoot }>
      { sponsors.map(({ image, alt, nombre }: ISponsorImage, i) => (
        <Grid key={ i } item md={ horizontal ? 1 : 6 } className={ styles.sponsorItem }>
          <Tooltip title={ nombre }>
            <Img className={ styles.sponsorImage } fluid={ image } { ...{ alt } } />
          </Tooltip>
        </Grid>
      )) }
    </Grid>
  )
}

export default Sponsors
