import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {}

export default makeStyles((theme: ITheme) => ({
  mainTargetContainer: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),

    textAlign: "center",
    color: theme.palette.text.lightText,

    borderRadius: theme.roundness,
    backgroundColor: theme.palette.extras.darkOlive,
  },

  mainTargetTitle: {
    width: "100%",
    fontWeight: theme.typography.fontWeightBold,
    textTransform: "uppercase",
  },

  mainStrategyContainer: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),

    textAlign: "center",
    color: theme.palette.text.lightText,

    borderRadius: theme.roundness,
    backgroundColor: theme.palette.extras.darkBlueType1,
  },

  rouletteContainer: {
    justifyContent: "center",

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
  },
}))
