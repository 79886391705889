import { Grid, Typography } from "@material-ui/core"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import * as React from "react"
import { FC, useEffect, useMemo, useState } from "react"
import { MapComponent } from ".."
import { PageNavigator, SectionContainer } from "../../_layout"
import { HEIGHT } from "../../_layout/Layout/Layout"

import useStyles from "./styles"

interface IMapController {
  maps: IMapData[]
  sectionID: string
}

export interface IMap {
  id: string
  name: string
  shortName: string
  mapURL: string
}

export interface IMapData {
  categoria: string
  ordenCategoria: number
  maps: IMap[]
}

export interface IMapCategory {
  id: string
  title: string
  data: IMap[]
  orden: number
}

const MapController: FC<IMapController> = ({ maps, sectionID }) => {
  const styles = useStyles()
  const [currentSelection, setCurrentSelection] = useState<IMap>(null)
  const [currentCategory, setCurrentCategory] = useState<IMapCategory>(null)

  const breakpoint = useBreakpoint()

  const mapCategories: IMapCategory[] = useMemo(() => {
    return maps.map((mapCategory, i) => ({
      id: i.toString(),
      title: mapCategory.categoria,
      orden: mapCategory.ordenCategoria,

      data: mapCategory.maps.map(map => ({
        id: map.id,
        title: map.shortName,
        ...map
      }))
    }))
  }, [])

  useEffect(() => {
    if (mapCategories.length > 0) {
      setCurrentSelection(mapCategories[0].data[0])
      setCurrentCategory(mapCategories[0])
    }
  }, [])


  return (
      <Grid container className={ styles.root }>
        <PageNavigator
          titleStrong="Mapas digitales"
          instructions={"2. Selecciona el mapa mapa que quieras consultar"}
          title="Selecciona un mapa"
          data={ mapCategories.sort((a, b) =>
            a.orden > b.orden
              ? 1
              : b.orden > a.orden
              ? -1
              : 0
          ) }
          loading={ false }
          loadingText="Cargando mapas..."
          scrollOffset={ HEIGHT * 2 }
          mobileAction={ () =>
            typeof window !== "undefined" &&
            window.open(currentSelection.mapURL)
          }
          { ...{ currentSelection, setCurrentSelection, setCurrentCategory } }
        >
          { !breakpoint.sm && (
            <Grid container item className={ styles.mapContainer }>
              {
                currentSelection ? (
                    <MapComponent withNotes mapURL={ currentSelection.mapURL } />
                  ) :
                  <Grid item container justify="center" alignItems="center" xs>
                    <Typography variant="h6">
                      No existen mapas en esta categoría.
                    </Typography>
                  </Grid>
              }
            </Grid>
          ) }
        </PageNavigator>
      </Grid>
  )
}

export default MapController
