import { makeStyles } from "@material-ui/core"
import { FluidObject } from "gatsby-image"
import { ITheme } from "../../../App.theme"

interface IStyleProps {
  image: FluidObject
  imageBG: string
}

export default makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4, 0),
    },
  },

  imgRoot: {
    alignItems: "center",
    justifyContent: "center",
    cursor: 'pointer'
  },

  imgContainer: {
    borderTopLeftRadius: theme.roundness,
    borderBottomLeftRadius: theme.roundness,
  },

  img: {
    flexGrow: 1,
    borderTopLeftRadius: theme.roundness,
    borderBottomLeftRadius: theme.roundness,
    maxHeight: '40vh',
  },

  btnContainer: {
    justifyContent: "center",
    padding: theme.spacing(4, 0),
  },

  leftPanel: {
    [theme.breakpoints.up("md")]: {
      color: theme.palette.text.lightText,
      padding: ({ image }: IStyleProps) =>
        image ? theme.spacing(4) : theme.spacing(0),

      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: ({ imageBG }: IStyleProps) => imageBG,
      borderTopRightRadius: theme.roundness,
      borderBottomRightRadius: theme.roundness,
    },
  },

  article: {
    "& p": {
      lineHeight: theme.lineHeight(2),
      [theme.breakpoints.up("sm")]: {
        fontSize: theme.typography.body1.fontSize,
      },
    },
  },
}))
