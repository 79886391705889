import { Grid } from "@material-ui/core"
import { Typography } from "@material-ui/core/"
import * as React from "react"
import { FC } from "react"
import NewsletterModule from "../../../lib/NewsletterModule"
import { SectionContainer, SectionTitle } from "../../_layout"
import NewsletterForm from "./NewsletterForm/NewsletterForm"


import useStyles from "./styles"

interface INewsletter {
  sectionID: string
}

const Newsletter: FC<INewsletter> = ({ sectionID }) => {
  const styles = useStyles()

  const handleSubscription = async ({ email }) => {
    try {
      await NewsletterModule().subscribeToNewsletter(email)
    } catch (err) {
      console.log(err)
    }
  }


  return (
    <SectionContainer dark { ...{ sectionID } }>
      <SectionTitle dark title="¡Suscríbete!" />
      <Grid container className={ styles.newsletterRoot }>
        <Grid item md={ 7 } className={ styles.subtitleContainer }>
          <Typography>
            ¡Suscríbete a nuestra lista de distribución de información sobre inclusión financiera!
            <br />
            <br />
            Te enviaremos información relevante a tu correo electrónico.
          </Typography>
        </Grid>
        <Grid item md={ 5 } container className={ styles.formContainer }>
          <NewsletterForm onFormSubmit={ handleSubscription } />
        </Grid>
      </Grid>
    </SectionContainer>
  )
}

export default Newsletter
