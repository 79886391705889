import { Grid, Typography } from "@material-ui/core/"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import * as React from "react"

import useStyles from "./styles"

const getIcon = graphql`
    {
        icon: file(relativePath: { eq: "expand.png" }) {
            childImageSharp {
                fluid(maxWidth: 50) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

interface IMap {
  mapURL: string
  withNotes?: boolean
}

const Map: React.FC<IMap> = ({ withNotes, mapURL }) => {
  const { icon } = useStaticQuery(getIcon)
  const styles = useStyles()

  return (
    <Grid container className={ styles.mapContainer }>

      {
        withNotes && <Grid item container className={ styles.notes }>
          <Grid item xs={ 11 }>
            <Typography variant={ "body2" } component={ "span" }>
              <strong>Nota: </strong>
              Puedes abrir el mapa en pantalla completa para una mejor
              visualización seleccionando el icono para expandir a pantalla completa.
            </Typography>
          </Grid>

          <Grid item xs={ 1 }>
            <Img className={ styles.icon } fluid={ icon.childImageSharp.fluid } alt={ "expandir" } />
          </Grid>
        </Grid>
      }

      <Grid item className={ styles.mapResponsive }>
        <iframe
          title="Mapa"
          width="100%"
          height="520"
          frameBorder="0"
          src={ mapURL }
          allowFullScreen
          // webkitallowfullscreen="webkitallowfullscreen"
          // mozallowfullscreen="mozallowfullscreen"
          // oallowfullscreen="oallowfullscreen"
          // msallowfullscreen="msallowfullscreen"
        />
      </Grid>

    </Grid>
  )
}

export default Map
