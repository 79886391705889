import API from "@aws-amplify/api"
import { ListNewslettersQuery, ListVariableEnifsQueryVariables } from "../API"
import { callGraphQL, GraphQLOptions } from "../context/contextHelpers"
import { createNewsletter } from "../graphql/mutations"
import { listNewsletters } from "../graphql/queries"

const NewsletterModule = () => {
  return {
    getNewsletterSubscriptions: async () => {
      return callGraphQL<ListNewslettersQuery,
        GraphQLOptions<ListVariableEnifsQueryVariables>>(listNewsletters, {
        variables: {
          // @ts-ignore
          limit: 2000
        }
      })
    },
    subscribeToNewsletter: async (email: string) => {
      return API.graphql({
        query: createNewsletter,
        variables: {
          input: {
            email
          }
        }
      })
    }
  }
}

export default NewsletterModule