import { Box, Grid, Tooltip, Typography } from "@material-ui/core"
import * as React from "react"
import { useContext, useState } from "react"
import Fade from "react-reveal/Fade"
import theme from "../../../App.theme"
import { Context as ModalsContext, IModalContext } from "../../../context/ModalContext"
import { useIndicadores } from "../../../hooks"

import { ActivityLoader, JelloWrapper } from "../../_common"

import useStyles from "./styles"
import { Wheel } from "./Wheel"

interface ISpinningWheel {
}

const backgroundColors = [
  theme.palette.extras.darkOlive,
  theme.palette.extras.darkBlueType1,
  theme.palette.extras.darkBlue,
  theme.palette.extras.orange,
  theme.palette.extras.olive,
  theme.palette.extras.blueType1,
  theme.palette.extras.blueType2,
  theme.palette.extras.yellow
]
const textColors = [
  theme.palette.text.lightText,
  theme.palette.text.lightText,
  theme.palette.text.lightText,
  theme.palette.text.lightText,
  theme.palette.text.lightText,
  theme.palette.text.lightText
]
const outerBorderColor = theme.palette.background.paperLight
const outerBorderWidth = theme.spacing(1)

const innerBorderColor = theme.palette.background.paperLight
const innerBorderWidth = theme.spacing(1)

const innerRadius = theme.spacing(0)
const radiusLineColor = theme.palette.background.paperLight
const radiusLineWidth = theme.spacing(1)

const fontSize = 18
const textDistance = theme.spacing(7)

// *******************************

const SpinningWheel: React.FC<ISpinningWheel> = ({}) => {
  const [mustSpin, setMustSpin] = useState<boolean>(false)
  const [currentOption, setCurrentOption] = useState<number>(null)
  const [currentColor, setCurrentColor] = useState<string>(null)
  const [loadingNext, setLoadingNext] = useState<boolean>(false)

  const { openModal } = useContext<IModalContext>(ModalsContext)

  const styles = useStyles({ backgroundColor: currentColor })

  const { indicadores, loadingIndicadores } = useIndicadores()

  const handleSpinClick = (option: number) => {
    setLoadingNext(true)
    setCurrentOption(option)
    setMustSpin(true)
  }

  if (loadingIndicadores) {
    return <ActivityLoader text="Cargando objetivos..." />
  }

  const ExpandedContent = () => {
    return (
      <Grid container item xs className={ styles.expandedContent }>
        <Grid item xs container direction="column">
          <Fade>
            <Typography className={ styles.expandedContentTitle }>
              { indicadores[currentOption].title }
            </Typography>
            <Typography className={ styles.expandedContentDescription }>
              { indicadores[currentOption].data[0].descripcion }
            </Typography>
            <Grid>
              { indicadores[currentOption]?.data.length > 1 && (
                <Typography className={ styles.expandedContentSubtitle }>
                  Estrategias
                </Typography>
              ) }
            </Grid>
            <ul className={ styles.expandedBullets }>
              { indicadores[currentOption].data?.map(
                (e, i) =>
                  e.title !== indicadores[currentOption].title && (
                    <Typography key={ i }>
                      <strong>{ e.title }</strong> - { e.descripcion }
                    </Typography>
                  )
              ) }
            </ul>
          </Fade>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container className={ styles.wrapper }>


      <Grid container item xs className={ styles.selectorContainer }>
        { indicadores.map((e, i) => {
          return (
            <Box
              key={ i }
              m={ 1 }
              className={ styles.selectorItem }
              onClick={ () => !loadingNext && handleSpinClick(i) }
              style={ { borderBottomColor: backgroundColors[i] } }
            >
              <JelloWrapper>
                <Tooltip title={ e.data[0].descripcion }>
                  <Typography
                    component="span"
                    id={ i === currentOption ? "selector-active" : "" }
                  >
                    { e.title }
                    <strong>{ i !== indicadores.length - 1 && " - " }</strong>
                  </Typography>
                </Tooltip>
              </JelloWrapper>
            </Box>
          )
        }) }
      </Grid>

      <Tooltip title="Selecciona un objetivo de la lista para ver más información.">
        <Grid container item xs className={ styles.wheelRoot }>
          <Grid container item xs>
            <Grid container item xs className={ styles.wheelContainer }>
              <Wheel
                data={ indicadores.map(e => ({
                  option:
                    e.title === "Estrategia transversal"
                      ? "E. Transversal"
                      : e.title
                })) }
                mustStartSpinning={ mustSpin }
                selectedOption={ currentOption }
                onStartSpinning={ () => {
                  setLoadingNext(true)
                } }
                onStopSpinning={ () => {
                  setMustSpin(false)
                  setCurrentColor(backgroundColors[currentOption])
                  setLoadingNext(false)
                  openModal(
                    indicadores[currentOption].title,
                    <ExpandedContent />
                  )
                } }
                { ...{
                  backgroundColors,
                  textColors,
                  fontSize,
                  outerBorderColor,
                  outerBorderWidth,
                  innerRadius,
                  innerBorderColor,
                  innerBorderWidth,
                  radiusLineColor,
                  radiusLineWidth,
                  textDistance
                } }
              />
            </Grid>
          </Grid>
        </Grid>
      </Tooltip>
    </Grid>
  )
}

export default SpinningWheel
