import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"


export default makeStyles((theme: ITheme) => ({
  newsletterRoot: {
  padding: theme.spacing(2,0)
  },

  iconContainer: {
    padding: theme.spacing(1, 0),
    fontSize: theme.typography.h2.fontSize,
    color: theme.palette.primary.main,
    alignItems: "center",
    justifyContent: "center"
  },

  titleContainer: {
    padding: theme.spacing(1, 0),
    textAlign: "center"
  },

  subtitleContainer: {
    padding: theme.spacing(0.5),
    color: theme.palette.text.lightText
  },

  formContainer: {
    alignItems: "center",
    justifyContent: "center"
  },

  quitContainer: {
    padding: theme.spacing(2, 0),
    textAlign: "center",
    cursor: 'pointer'
  }
}))
