import { Grid } from "@material-ui/core"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import * as React from "react"
import Zoom from "react-reveal/Zoom"
import { Button, Card } from "../../_common"
import { ICardItem } from "../../_common/Card/Card"
import { SectionContainer, SectionTitle } from "../../_layout"

import useStyles from "./styles"

interface INewsSection {
  news: ICardItem[]
  isCompleteList?: boolean
}

const NewsSection: React.FC<INewsSection> = ({ news, isCompleteList }) => {
  const styles = useStyles()
  const { sm } = useBreakpoint()

  return (
    <SectionContainer sectionID="ligas-de-interes">
      { news.length > 0 && (
        <>
          <SectionTitle title="Ligas de interés de inclusión financiera" />
          <Grid container={ !sm } className={ styles.bRoot }>
            { news.map(e => (

              <Card key={ e.id } data={ e } />

            )) }
          </Grid>
          { !isCompleteList && (
            <Grid container className={ styles.btnContainer }>
              <Button aria="Ver todo" goTo="/ligas-de-interes">
                Ver todo
              </Button>
            </Grid>
          ) }
        </>
      ) }
    </SectionContainer>
  )
}

export default NewsSection
