import { Grid } from "@material-ui/core"
import * as React from "react"
import { SpinningWheel } from ".."
import { SectionContainer, SectionText, SectionTitle } from "../../_layout"
import useStyles from "./styles"

interface ITargetsSection {
  sectionID: string
}

const TargetsSection: React.FC<ITargetsSection> = ({ sectionID }) => {
  const styles = useStyles()
  return (
    <SectionContainer { ...{ sectionID } }>
      <SectionTitle title="Conoce la estructura, avances y logros de la PNIF" />
      <SectionText text={"Selecciona del listado a continuación el objetivo o estrategia que deseas consultar.\n"} />
      <Grid container className={ styles.rouletteContainer }>
        <SpinningWheel />
      </Grid>
    </SectionContainer>
  )
}

export default TargetsSection
