import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { RotationContainer, RouletteContainer } from "./styles"

import {
  DEFAULT_BACKGROUND_COLORS,
  DEFAULT_FONT_SIZE,
  DEFAULT_INNER_BORDER_COLOR,
  DEFAULT_INNER_BORDER_WIDTH,
  DEFAULT_INNER_RADIUS,
  DEFAULT_OUTER_BORDER_COLOR,
  DEFAULT_OUTER_BORDER_WIDTH,
  DEFAULT_RADIUS_LINE_COLOR,
  DEFAULT_RADIUS_LINE_WIDTH,
  DEFAULT_TEXT_COLORS,
  DEFAULT_TEXT_DISTANCE,
  getRotationDegrees
} from "./utils"
import WheelCanvas from "./WheelCanvas"

export interface WheelData {
  option: string
  style?: StyleType
}

interface StyleType {
  backgroundColor?: string
  textColor?: string
}

interface Props {
  mustStartSpinning: boolean
  selectedOption: number
  data: WheelData[]
  onStopSpinning?: () => any
  onStartSpinning?: () => any
  backgroundColors?: string[]
  textColors?: string[]
  outerBorderColor?: string
  outerBorderWidth?: number
  innerRadius?: number
  innerBorderColor?: string
  innerBorderWidth?: number
  radiusLineColor?: string
  radiusLineWidth?: number
  fontSize?: number
  perpendicularText?: boolean
  textDistance?: number
}

const STARTED_SPINNING = "started-spinning"

const START_SPINNING_TIME = 100
const CONTINUE_SPINNING_TIME = 100
const STOP_SPINNING_TIME = 1000

const getLogo = graphql`
    {
        selector: file(relativePath: { eq: "roulette-selector.png" }) {
            childImageSharp {
                fluid(maxWidth: 850) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

export const Wheel = ({
                        mustStartSpinning,
                        selectedOption,
                        data,
                        onStopSpinning = () => null,
                        onStartSpinning = () => null,
                        backgroundColors = DEFAULT_BACKGROUND_COLORS,
                        textColors = DEFAULT_TEXT_COLORS,
                        outerBorderColor = DEFAULT_OUTER_BORDER_COLOR,
                        outerBorderWidth = DEFAULT_OUTER_BORDER_WIDTH,
                        innerRadius = DEFAULT_INNER_RADIUS,
                        innerBorderColor = DEFAULT_INNER_BORDER_COLOR,
                        innerBorderWidth = DEFAULT_INNER_BORDER_WIDTH,
                        radiusLineColor = DEFAULT_RADIUS_LINE_COLOR,
                        radiusLineWidth = DEFAULT_RADIUS_LINE_WIDTH,
                        fontSize = DEFAULT_FONT_SIZE,
                        perpendicularText = false,
                        textDistance = DEFAULT_TEXT_DISTANCE
                      }: Props) => {
  const { selector } = useStaticQuery(getLogo)
  const wheelData = useRef<WheelData[]>([...data])
  const [startRotationDegrees, setStartRotationDegrees] = useState(0)
  const [finalRotationDegrees, setFinalRotationDegrees] = useState(0)
  const [hasStartedSpinning, setHasStartedSpinning] = useState(false)
  const [hasStoppedSpinning, setHasStoppedSpinning] = useState(false)
  const [isCurrentlySpinning, setIsCurrentlySpinning] = useState(false)
  const [isDataUpdated, setIsDataUpdated] = useState(false)

  const mustStopSpinning = useRef<boolean>(false)

  useEffect(() => {
    const dataLength = data.length
    wheelData.current = [...data]
    for (let i = 0; i < dataLength; i++) {
      wheelData.current[i] = {
        ...data[i],
        style: {
          backgroundColor:
            data[i].style?.backgroundColor ||
            backgroundColors[i % backgroundColors.length],
          textColor:
            data[i].style?.textColor || textColors[i % textColors.length]
        }
      }
    }
    setIsDataUpdated(true)
  }, [data, backgroundColors, textColors])

  useEffect(() => {
    if (mustStartSpinning && !isCurrentlySpinning) {
      setIsCurrentlySpinning(true)
      startSpinning()
      const finalRotationDegreesCalculated = getRotationDegrees(
        selectedOption,
        data.length
      )
      setFinalRotationDegrees(finalRotationDegreesCalculated)
    }
  }, [mustStartSpinning])

  useEffect(() => {
    if (hasStoppedSpinning) {
      setIsCurrentlySpinning(false)
      setStartRotationDegrees(finalRotationDegrees)
    }
  }, [hasStoppedSpinning])

  const startSpinning = () => {
    onStartSpinning()
    setHasStartedSpinning(true)
    setHasStoppedSpinning(false)
    mustStopSpinning.current = true
    setTimeout(() => {
      if (mustStopSpinning.current) {
        mustStopSpinning.current = false
        setHasStartedSpinning(false)
        setHasStoppedSpinning(true)
        onStopSpinning()
      }
    }, START_SPINNING_TIME + CONTINUE_SPINNING_TIME + STOP_SPINNING_TIME)
  }

  const getRouletteClass = () => {
    if (hasStartedSpinning) {
      return STARTED_SPINNING
    }
    return ""
  }

  if (!isDataUpdated) {
    return null
  }

  return (
    <RouletteContainer>
      <RotationContainer
        className={ getRouletteClass() }
        startSpinningTime={ START_SPINNING_TIME }
        continueSpinningTime={ CONTINUE_SPINNING_TIME }
        stopSpinningTime={ STOP_SPINNING_TIME }
        startRotationDegrees={ startRotationDegrees }
        finalRotationDegrees={ finalRotationDegrees }
      >
        <WheelCanvas
          width="900"
          height="900"
          data={ wheelData.current }
          outerBorderColor={ outerBorderColor }
          outerBorderWidth={ outerBorderWidth }
          innerRadius={ innerRadius }
          innerBorderColor={ innerBorderColor }
          innerBorderWidth={ innerBorderWidth }
          radiusLineColor={ radiusLineColor }
          radiusLineWidth={ radiusLineWidth }
          fontSize={ fontSize }
          perpendicularText={ perpendicularText }
          textDistance={ textDistance }
        />
      </RotationContainer>

      <Img
        style={ {
          position: "absolute",
          zIndex: 5,
          width: "17%",
          right: "6px",
          top: "15px"
        } }
        fluid={ selector.childImageSharp.fluid }
        alt="selector"
      />
    </RouletteContainer>
  )
}
