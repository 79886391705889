import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

export default makeStyles((theme: ITheme) => ({
  bRoot: {
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
  },

  btnContainer: {
    justifyContent: "center",
    padding: theme.spacing(4, 0),
    marginTop: theme.spacing(6),
  },
}))
