import { Grid } from "@material-ui/core/"
import * as React from "react"
import theme from "../../../../App.theme"
import { FormSchemaTextObject, TAnyCallback } from "../../../../helpers/Types"
import { useFormUpdate } from "../../../../hooks"
import { Button } from "../../../_common"
import { FormTextInput } from "../../../_forms"
import { IFormStateOptions } from "../../../_forms/FormContainer/FormContainer"


import useSchemas from "./form.schema"

export interface NewsletterFormSchemaData {
  email: FormSchemaTextObject
}

interface NewsletterFormProps {
  onFormSubmit: TAnyCallback
  stateOptions?: IFormStateOptions
}

const NewsletterForm = React.forwardRef(({ onFormSubmit }: NewsletterFormProps, ref: any) => {
  const { initialState, validationStateSchema } = useSchemas()

  const {
    updatedData,
    handleUpdateData,
    handleOnSubmit,
    buttonState,
    isButtonLoading
  } = useFormUpdate<NewsletterFormSchemaData>(
    initialState,
    validationStateSchema,
    async cleanData => {
      await onFormSubmit(cleanData)
      updatedData.email.value = ""
    }
  )

  return (
    <Grid container>
      <Grid item>
        <FormTextInput
          { ...{ ref } }
          required
          name="email"
          label=""
          value={ updatedData.email.value }
          errorValue={ null }
          onChange={ handleUpdateData }
          placeholder={ "Correo electrónico" }
        />
      </Grid>
      <Grid xs item style={ { margin: theme.spacing(0, 2) } }>
        <Button
          small
          light
          aria={ "Suscribirse al boletín" }
          handleClick={ handleOnSubmit }
          disabled={ !buttonState }
          isLoading={ isButtonLoading }
        >
          Suscríbeme
        </Button>
      </Grid>
    </Grid>
  )
})

export default NewsletterForm
