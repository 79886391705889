import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Grid } from "@material-ui/core"
import * as React from "react"

import { Button } from "../../_common"
import rawOptions from "./rawOptions"
import useStyles from "./styles"
import { IThanksSection } from "./ThanksSection"

interface IContent extends Partial<IThanksSection> {
  title: string
  content: string
}

const Content: React.FC<IContent> = ({ noButton, title, content }) => {
  const styles = useStyles({})

  const contenidoBodyJSON = JSON.parse(content)

  return (
    <Grid className={ styles.contentRoot }>
      <article className={ styles.article }>
        { documentToReactComponents(contenidoBodyJSON, rawOptions) }
      </article>

      { !noButton && (
        <Grid container className={ styles.btnContainer }>
          <Button aria="saber más" light goTo="/acerca">
            Saber más
          </Button>
        </Grid>
      ) }
    </Grid>
  )
}

export default Content
