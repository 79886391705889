import { Grid } from "@material-ui/core"
import { navigate } from "gatsby"
import { FluidObject } from "gatsby-image"
import Img from "gatsby-image/withIEPolyfill"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import * as React from "react"
import theme from "../../../App.theme"

import { Button } from "../../_common"
import { SectionContainer, SectionText, SectionTitle } from "../../_layout"

import useStyles from "./styles"

interface IDocumentos {
  title: string
  content: string
  sectionID: string
  cta?: string
  route?: string
  image?: FluidObject
  alt?: string
  imageBG?: string
}

const Documentos: React.FC<IDocumentos> = ({
                                             image,
                                             title,
                                             route,
                                             cta,
                                             alt,
                                             sectionID,
                                             imageBG = theme.palette.extras.darkOlive,
                                             content
                                           }) => {
  const styles = useStyles({ image, imageBG })
  const breakpoint = useBreakpoint()

  return (
    <SectionContainer { ...{ sectionID } }>
      <SectionTitle { ...{ title } } />
      <Grid container className={ styles.root }>
        { image && (
          <Grid item xs={ 12 } container className={ styles.imgRoot } md={ 6 } onClick={() => navigate(route)}>
            <Grid item container className={ styles.imgContainer }>
              <Img
                // @ts-ignore
                objectFit="contain"
                objectPosition="50% 50%"
                className={ styles.img }
                fluid={ image }
                { ...{ alt } }
              />
            </Grid>
          </Grid>
        ) }

        <Grid item xs={ 12 } className={ styles.leftPanel } md={ 6 }>
          <SectionText text={ content } />
          { cta && route && (
            <Grid item container className={ styles.btnContainer }>
              <Button light={ !breakpoint.sm } aria={ cta } goTo={ route }>
                { cta }
              </Button>
            </Grid>
          ) }
        </Grid>
      </Grid>
    </SectionContainer>
  )
}

export default Documentos

// Logos en comite de educacionfinanciera (contentful y gatsby)
// Orden de logotipos en educacion
