import { Grid, Typography } from "@material-ui/core"
import { navigate } from "gatsby"
import * as React from "react"
import { FC, useState } from "react"
import { Button } from "../../_common"

import useStyles from "./styles"

interface ICookies {
  cookies: string
}

const Cookies: FC<ICookies> = ({ cookies }) => {
  const LS_COOKIES_NAME = "pnif-cookies"

  const LS_COOKIES =
    typeof window !== "undefined" && localStorage.getItem(LS_COOKIES_NAME)

  const styles = useStyles()
  const [alreadyAccepted, setAccepted] = useState(
    Boolean(LS_COOKIES && JSON.parse(LS_COOKIES))
  )

  const handleClick = () => {
    setAccepted(true)
    typeof window !== "undefined" &&
    localStorage.setItem(LS_COOKIES_NAME, JSON.stringify(true))
  }

  const handleMoreInfo = () => {
    navigate("/legal")
  }

  if (alreadyAccepted) {
    return null
  }

  return (
    <Grid container className={ styles.cookiesRoot }>
      <Grid item xs>
        <Typography variant="h6">{ cookies }</Typography>
      </Grid>
      <Grid container item className={ styles.buttonContainer }>
        <Grid item className={ styles.buttonItem }>
          <Button
            light
            discrete
            aria="más información"
            handleClick={ handleMoreInfo }
          >
            Más información
          </Button>
        </Grid>
        <Grid item className={ styles.buttonItem }>
          <Button light aria="acepto" { ...{ handleClick } }>
            Acepto
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Cookies
