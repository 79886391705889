import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {}

export default makeStyles((theme: ITheme) => ({
  root: {
    [theme.breakpoints.up("md")]: {},
  },

  contentRoot: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.extras.darkBlue,

    borderRadius: theme.roundness,
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(2,0),
    },

    "& h6": {
      fontSize: theme.typography.h5.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.lightText,
    },

    "& p": {
      fontSize: theme.typography.body1.fontSize,
      color: theme.palette.text.lightText,
    },
  },

  btnContainer: {
    justifyContent: "center",
    padding: theme.spacing(4, 0),
  },

  sponsorsRoot: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
    },
  },

  sponsorItem: {
    width: "15vw",
    height: "15vw",
    margin: theme.spacing(0, 1),

    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(2),
      width: "10vw",
      height: "10vw",
    },
  },

  sponsorImage: {
    width: "100%",
  },
}))
