import styled from "styled-components"
import { makeStyles } from "@material-ui/core"

export default makeStyles((theme) => ({
  wrapper: {
    flexDirection: "column",
  },

  wheelRoot: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
    },
  },

  wheelContainer: {
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
  },

  contentContainer: {
    textAlign: "center",
    color: theme.palette.text.lightText,
    padding: theme.spacing(2),
  },

  contentTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 0,

    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.h6.fontSize,
    },
  },

  contentDescription: {
    fontSize: theme.typography.body2.fontSize,

    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.body2.fontSize,
    },
  },

  expandedContent: {
    marginTop: theme.spacing(2),
    minHeight: 383,
    padding: theme.spacing(4),
    justifyContent: "flex-start",
    alignItems: "flex-start",
    borderRadius: theme.roundness,

    backgroundColor: ({ backgroundColor }) => backgroundColor,

    "& ul": {
      paddingLeft: theme.spacing(2),
    },

    [theme.breakpoints.up("md")]: {
      width: "auto",
      fontSize: theme.typography.body2.fontSize,
    },
  },

  expandedContentTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h4.fontSize,
    textTransform: "uppercase",
    color: theme.palette.text.lightText,
    marginBottom: theme.spacing(2),
  },

  expandedContentSubtitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h6.fontSize,
    textTransform: "uppercase",
    color: theme.palette.text.lightText,
    margin: theme.spacing(2, 0),
  },

  expandedContentDescription: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.lightText,
  },

  expandedBullets: {
    color: theme.palette.text.lightText,

    "& p": {
      lineHeight: theme.lineHeight(2.5),
      fontSize: theme.typography.body2.fontSize,
    },
  },

  selectorContainer: {
    padding: theme.spacing(2, 0),
    justifyContent: "flex-start",

    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
      padding: theme.spacing(2),
    },
  },

  selectorItem: {
    cursor: "pointer",

    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(2, 1),
      padding: theme.spacing(1,2),
      borderWidth: 0,
      borderStyle: "solid",
      // borderRightWidth: theme.spacing(0.1),
      // borderRightColor: theme.palette.secondary.main,

      borderBottomWidth: theme.spacing(0.25),
      // borderBottomColor: ({ backgroundColor }) => backgroundColor
    },

    "& span": {
      transition: theme.palette.extras.transitions.fastEaseInOut,
      fontSize: theme.typography.body2.fontSize,
      color: theme.palette.text.disabled,

      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.body1.fontSize,

        "& strong": {
          display: "none",
        },

        "&:hover": {
          color: theme.palette.secondary.main,
        },
      },
    },
    "& #selector-active": {
      padding: theme.spacing(0.5, 0),
      color: theme.palette.text.primary,
      // fontWeight: theme.typography.fontWeightBold,
      // borderWidth: 0,
      // borderBottomWidth: theme.spacing(0.5),
      // borderStyle: "solid",
      // borderBottomColor: ({ backgroundColor }) => backgroundColor,
    },
  },

  loaderContainer: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },

  helpText: {
    textAlign: "right",
    fontSize: theme.typography.body2.fontSize,
    paddingRight: theme.spacing(5),
  },
}))

const NonDraggableImage = styled.img`
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
`

export const RouletteContainer = styled.div`
  position: relative;
  width: 80vw;
  max-width: 445px;
  height: 80vw;
  max-height: 445px;
  object-fit: contain;
  flex-shrink: 0;
  z-index: 5;
  pointer-events: none;
`

export const RotationContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(${props => props.startRotationDegrees}deg);
  &.started-spinning {
    animation: spin ${({ startSpinningTime }) => startSpinningTime / 1000}s
        cubic-bezier(0.71, -0.29, 0.96, 0.9) 0s 1 normal forwards running,
      continueSpin 94ms linear
        ${({ startSpinningTime }) => startSpinningTime / 1000}s 1 normal
        forwards running,
      stopSpin ${({ stopSpinningTime }) => stopSpinningTime / 1000}s
        cubic-bezier(0, 0, 0.35, 1.02)
        ${({ startSpinningTime, continueSpinningTime }) =>
          (startSpinningTime + continueSpinningTime) / 1000}s
        1 normal forwards running;
  }
  @keyframes spin {
    from {
      transform: rotate(${props => props.startRotationDegrees}deg);
    }
    to {
      transform: rotate(${props => props.startRotationDegrees}deg);
    }
  }
  @keyframes continueSpin {
    from {
      transform: rotate(${props => props.startRotationDegrees}deg);
    }
    to {
      transform: rotate(${props => props.startRotationDegrees}deg);
    }
  }
  @keyframes stopSpin {
    from {
      transform: rotate(${props => props.startRotationDegrees}deg);
    }
    to {
      transform: rotate(${props => props.finalRotationDegrees}deg);
    }
  }
`

export const RouletteSelectorImage = styled(NonDraggableImage)`
  position: absolute;
  z-index: 5;
  width: 17%;
  right: 6px;
  top: 15px;
`

export const WheelCanvasStyle = styled.canvas`
  width: 98%;
  height: 98%;
`
