import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {
}

export default makeStyles((theme: ITheme) => ({
  cookiesRoot: {
    flexDirection: "column",
    padding: theme.spacing(2),
    width: "100%",
    position: "fixed",
    zIndex: 9999,
    bottom: 10,
    background: theme.palette.background.darkBackground,
    color: theme.palette.text.lightText,
    boxShadow: theme.shadows[3],
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 6),
      maxHeight: 300,
    }
  },

  buttonContainer: {
    position: "relative",
    zIndex: 9999,
    padding: theme.spacing(2),
    width: "100%",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end"
    }
  },

  buttonItem: {
    margin: theme.spacing(2, 1),

    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(0, 1),
    },
  },
}))
