import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"
import { HEIGHT } from "../../_layout/Layout/Layout"

interface IStyleProps {}

export default makeStyles((theme: ITheme) => ({
  root: {
    [theme.breakpoints.up("md")]: {},
  },

  headerContainer: {},

  controllerContainer: {
    [theme.breakpoints.up("md")]: { height: `calc(100vh - ${HEIGHT * 2}px)` },
  },

  panel: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paperLight,
    borderRadius: theme.roundness,
    overflow: "hidden",
  },

  headerTitleContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.extras.darkBlue,

    "& h3": {
      fontSize: theme.typography.h6.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.lightText,
    },
  },

  headerContentContainer: {
    padding: theme.spacing(2),
    alignItems: "center",
    borderRadius: theme.roundness,
    backgroundColor: theme.palette.background.paperLight,

    "& p": {
      fontSize: theme.typography.body2.fontSize,
      color: theme.palette.text.disabled,
    },
  },

  mapContainer: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",

    "& p": {
      fontSize: theme.typography.h5.fontSize,
    },

    [theme.breakpoints.up("md")]: { height: `calc(90vh - ${HEIGHT * 2}px)` },
  },

  mapNavigatorContainer: {
    maxHeight: "100%",
    padding: theme.spacing(1),
  },

  mapNavigator: {
    maxHeight: "100%",

    padding: theme.spacing(0.5),
    borderRadius: theme.roundness,
    backgroundColor: theme.palette.extras.darkBlue,
  },

  mapNavigatorTextContainer: {
    padding: theme.spacing(0.5),
    writingMode: "vertical-rl",
    transform: "scale(-1, -1)",
    color: theme.palette.text.lightText,
    "& span": {
      fontSize: theme.typography.body2.fontSize,
      margin: theme.spacing(1, 0),
      "& strong": {
        fontSize: theme.typography.body1.fontSize,
      },
    },
  },

  maps: {
    justifyContent: "flex-start",
    alignItems: "center",
    maxHeight: "100%",
    overflow: "scroll",
  },

  mapCategory: {
    justifyContent: "flex-start",
    alignItems: "center",
    padding: theme.spacing(0.5),
    margin: theme.spacing(1, 0),

    "& h6": {
      width: "100%",
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.lightText,
    },
  },

  mapItem: {
    width: "100%",
    cursor: "pointer",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: theme.spacing(0.5, 0),
    "& #map-nav-active": {
      padding: theme.spacing(0.5, 0),
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.lightText,
    },
    "& span": {
      transition: theme.palette.extras.transitions.default,
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.text.lightMild,

      "&:hover": {
        color: theme.palette.text.lightMild,
      },
    },
  },
}))
