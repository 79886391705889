export { default as Cookies } from "./Cookies/Cookies"
export { default as DataSection } from "./DataSection/DataSection"
export { default as Documentos } from "./Documentos/Documentos"
export { default as MapsSection } from "./MapsSection/MapsSection"
export { default as NewsSection } from "./NewsSection/NewsSection"
export { default as Newsletter } from "./Newsletter/Newsletter"
export { default as SpinningWheel } from "./SpinningWheel/SpinningWheel"
export { default as TargetsSection } from "./TargetsSection/TargetsSection"
export { default as ThanksSection } from "./ThanksSection/ThanksSection"
export { default as VideoSection } from "./VideoSection/VideoSection"
