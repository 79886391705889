import { FluidObject } from "gatsby-image"
import * as React from "react"
import { FC } from "react"
import { DividedSection, SectionContainer, SectionTitle } from "../../_layout"
import Content from "./Content"
import Sponsors from "./Sponsors"

import useStyles from "./styles"

export interface ISponsorImage {
  nombre?: string
  image: FluidObject
  alt: string
}

export interface IThanksSection {
  sponsors: ISponsorImage[]
  sectionID: string
  title: string
  content: string
  noButton?: boolean
}

const ThanksSection: FC<IThanksSection> = ({
                                             sponsors,
                                             sectionID,
                                             noButton,
                                             title,
                                             content
                                           }) => {
  const styles = useStyles({})

  return (
    <SectionContainer { ...{ sectionID } }>
      <SectionTitle title="Acerca de esta plataforma" />
      <DividedSection
        components={ [
          <Sponsors { ...{ sponsors } } />,
          <Content { ...{ noButton, title, content } } />
        ] }
      />
    </SectionContainer>
  )
}

export default ThanksSection
