import { Grid } from "@material-ui/core"
import * as React from "react"
import { Button } from "../../_common"

import { SectionText } from "../../_layout"
import useStyles from "./styles"

interface IContent {
  content: string
}

const Content: React.FC<IContent> = ({ content }) => {
  const styles = useStyles()

  const paragraphs: string[] = [content]
  return (
    <Grid className={ styles.contentRoot }>
      { paragraphs.map((e, i) => (
        <SectionText key={ i } regularBottom text={ e } />
      )) }
      <Grid container className={ styles.btnContainer }>
        <Button aria="Explorar mapas" light goTo="/mapas">
          Explorar mapas
        </Button>
      </Grid>
    </Grid>
  )
}

export default Content
