import * as React from "react"
import { FC } from "react"
import { DividedSection, SectionContainer, SectionTitle } from "../../_layout"
import { MapComponent } from "../../MapsModule"
import Sponsors from "../ThanksSection/Sponsors"
import Content from "./Content"

import useStyles from "./styles"

interface IMapsSection {
  mapURL: string
  content: string
  title: string
}

const MapsSection: FC<IMapsSection> = ({ mapURL, content, title }) => {

  return (
    <SectionContainer sectionID={ "maps-section" }>
      <SectionTitle  { ...{ title } } />
      <DividedSection
        components={ [
          <Content { ...{ content } } />,
          <MapComponent { ...{ mapURL } } />
        ] }
      />
    </SectionContainer>
  )
}

export default MapsSection
