export const getRotationDegrees = (
  prizeNumber: number,
  numberOfPrizes: number
) => {
  const degreesPerPrize = 360 / numberOfPrizes

  const initialRotation = 43 + degreesPerPrize / 2

  const randomDifference = (-1 + Math.random() * 2) * degreesPerPrize * 0.35

  const prizeRotation =
    degreesPerPrize * (numberOfPrizes - prizeNumber) -
    initialRotation +
    randomDifference

  return numberOfPrizes - prizeNumber > numberOfPrizes / 2
    ? -360 + prizeRotation
    : prizeRotation
}

export const clamp = (min: number, max: number, val: number) =>
  Math.min(Math.max(min, +val), max)

export const DEFAULT_BACKGROUND_COLORS = ["darkgrey", "lightgrey"]
export const DEFAULT_TEXT_COLORS = ["black"]
export const DEFAULT_OUTER_BORDER_COLOR = "black"
export const DEFAULT_OUTER_BORDER_WIDTH = 5
export const DEFAULT_INNER_RADIUS = 0
export const DEFAULT_INNER_BORDER_COLOR = "black"
export const DEFAULT_INNER_BORDER_WIDTH = 0
export const DEFAULT_RADIUS_LINE_COLOR = "black"
export const DEFAULT_RADIUS_LINE_WIDTH = 5
export const DEFAULT_FONT_SIZE = 20
export const DEFAULT_TEXT_DISTANCE = 60




