import { Grid, Typography } from "@material-ui/core"
import BackgroundImage from "gatsby-background-image"
import { FluidObject } from "gatsby-image"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import * as React from "react"
import { useEffect, useState } from "react"
import Fade from "react-reveal/Fade"
import { Button } from "../../_common"
import { SectionContainer, SectionTitle } from "../../_layout"

import useStyles from "./styles"

interface IDataSection {
  image: FluidObject
}

const DataSection: React.FC<IDataSection> = ({ image }) => {
  const [extraInfoVisible, setExtraInfoVisible] = useState<boolean>(false)
  const styles = useStyles()
  const { sm, md, l } = useBreakpoint()

  useEffect(() => {
    if (sm) {
      setExtraInfoVisible(true)
    }
  }, [sm])

  return (
    <SectionContainer sectionID="data-section">
      <SectionTitle title="Explora los datos" />
      <Grid container className={ styles.root }>
        <Grid
          container
          className={ styles.rightPanel }
          onMouseOver={ () => !sm && setExtraInfoVisible(true) }
          onMouseLeave={ () => !sm && setExtraInfoVisible(false) }
        >
          <Grid item className={ styles.contetTextContainer }>
            <Typography component="span" className={ styles.contentText }>
              Explora y descarga
            </Typography>
          </Grid>
          <Grid item xs={ !sm }>
            <Fade
              right
              delay={ !extraInfoVisible ? 0 : 500 }
              when={ extraInfoVisible }
            >
              <Grid container className={ styles.hiddenContainer } spacing={ 2 }>
                <Grid item className={ styles.contetTextContainer }>
                  <Typography
                    component="span"
                    className={ styles.contentHiddenText }
                  >
                    datos de inclusión financiera
                  </Typography>
                </Grid>

                <Grid item>
                  <Button
                    aria="Explorar datos"
                    goTo="/datos"
                    light
                    small
                  >
                    Explorar datos
                  </Button>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>

        <Grid container item className={ styles.leftPanel }>
          <BackgroundImage className={ styles.image } fluid={ image } />
        </Grid>
      </Grid>
    </SectionContainer>
  )
}

export default DataSection
