import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {}

export default makeStyles((theme: ITheme) => ({
  videoContainer: {
    padding: theme.spacing(0.25),
    overflow: "hidden",
    borderRadius: theme.roundness,
    backgroundColor: theme.palette.extras.blueType1,
  },

  videoResponsive: {
    height: 0,
    overflow: "hidden",
    paddingBottom: "56.25%",
    paddingTop: "30px",
    position: "relative",
    "& iframe, object, embed": {
      width: "100%",
      height: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      borderRadius: theme.roundness,
    },
  },
}))
