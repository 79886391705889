import { validationStore } from "../../../../helpers/helperFunctions"
import { FormSchemaTextObject, FormSchemaValidation } from "../../../../helpers/Types"


interface INewsletterFormType<T> {
  email: T
}

interface Schema {
  initialState: INewsletterFormType<FormSchemaTextObject>
  validationStateSchema: INewsletterFormType<FormSchemaValidation>
}

export default (): Schema => ({
  initialState: {
    email: { value: "", error: "" },
  },
  validationStateSchema: {
    email: {
      required: true,
      validator: {
        regEx: validationStore.email,
        error: "Favor de introducir un correo válido.",
      },
    },
  },
})
