import * as React from "react"


import { SectionContainer } from "../../_layout"

import useStyles from "./styles"

interface IVideo {
  videoYoutubeID: string
}

const VideoSection: React.FC<IVideo> = ({ videoYoutubeID }) => {
  const styles = useStyles()
  return (
    <div className={ styles.videoContainer }>
      <div className={ styles.videoResponsive }>
        <iframe
          title="Video de youtube"
          src={ `https://www.youtube.com/embed/${ videoYoutubeID }?modestbranding=1&autohide=1&showinfo=0&controls=1` }
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  )
}

export default VideoSection
