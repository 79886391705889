import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {}

const HEIGHT: number = 400

export default makeStyles((theme: ITheme) => ({
  root: {
    position: "relative",
    height: HEIGHT * 0.7,
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      height: HEIGHT * 0.5,
    },

    [theme.breakpoints.up("md")]: {
      height: HEIGHT,
    },
  },

  rightPanel: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 10,

    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: theme.spacing(2),
    paddingRight: theme.spacing(0),

    width: "100%",
    height: "100%",

    backgroundColor: theme.palette.extras.darkBlueType1,

    transition: "all 1.2s ease-in-out",
    borderRadius: theme.roundness,

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
      width: "50%",

      clipPath: "polygon(0 0, 100% 0, 75% 100%, 0% 100%)",

      "&:hover": {
        width: "80%",
      },
    },
  },

  leftPanel: {
    display: "none",
    width: "100%",
    transition: "width 1.2s ease-in-out",
    clipPath: "polygon(25% 0%, 100% 0, 100% 100%, 0 100%)",
    borderRadius: theme.roundness,
    overflow: "hidden",

    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },

  image: {
    height: HEIGHT,
    width: "100%",
  },

  contetTextContainer: {
    margin: theme.spacing(1, 0),
  },

  contentText: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.lightText,
    marginRight: theme.spacing(1.5),
  },

  contentHiddenText: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.lightText,
    opacity: 0.8,
  },

  hiddenContainer: {
    alignItems: "center",
  },
}))
