import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

export default makeStyles((theme: ITheme) => ({
  mapContainer: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(2),
    overflow: "hidden",
    borderRadius: theme.roundness,
    backgroundColor: theme.palette.extras.none
  },

  mapResponsive: {
    // height: 0,
    overflow: "hidden",
    paddingBottom: "56.25%",
    paddingTop: "30px",
    position: "relative",
    borderRadius: theme.roundness,
    width: "100%",
    height: "100%",
    "& iframe, object, embed": {
      width: "100%",
      height: "100%",
      position: "absolute",
      left: 0,
      top: 0
    }
  },

  notes: {
    margin: theme.spacing(2, 0)
  },

  icon: {
    width: 50,
    height: 50
  }
}))
